<template>
  <div id="user-container" v-if="accessibleRoutes.log">
    <div id="user-btn-group" no-gutters>
      <div class="col-12 p-responsive">
        <div class="col-12 ktc-logs">
          <v-btn-toggle v-model="toggle">
            <router-link to="/logs/users">
              <v-btn :ripple="false" :disabled="toggle == 0">Users Access Logs</v-btn>
            </router-link>
            <router-link to="/logs/devices">
              <v-btn :ripple="false" :disabled="toggle == 1" class="">Lighting Failure Logs</v-btn>
            </router-link>
            <router-link to="/logs/sc">
              <v-btn :ripple="false" :disabled="toggle == 2" class="">SC Status Logs</v-btn>
            </router-link>
            <router-link to="/logs/schedules">
              <v-btn :ripple="false" :disabled="toggle == 3" class="">Schedules Logs</v-btn>
            </router-link>
          </v-btn-toggle>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0 pb-0">
      <router-view ref="logs_ref"></router-view>
    </div>
  </div>
</template>
<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
import { userProfileService } from "@/services";
export default {
  data() {
    return {
      toggle: 0,
      interval_browser: null,
    };
  },
  mounted(){
    this.interval_browser = setInterval(() => {
      userProfileService.getCurrentUserProfile();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval_browser);
  },
  created() {
    this.toggle = 0;
    if (this.$router.currentRoute.name == "Devices Logs") {
      this.toggle = 1;
    } else if (this.$router.currentRoute.name == "Sc Logs") {
      this.toggle = 2;
    } else if (this.$router.currentRoute.name == "Schedule Logs") {
      this.toggle = 3;
    }

  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    }
  },
};
</script>

<style lang="css" scoped>
  .ktc-logs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ktc-logs .ktc-logs-select {
    width: 135px;
    height: 45px;
    border-radius: 12px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.8);
    border: solid 1px #b3b3b3;
    color:#f7ffc9;
    padding-left: 15px;
    text-transform: uppercase;
  }
</style>